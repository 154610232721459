<template>
  <v-container
    id="issue-categories"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        v-for="(item) in issuesCategories"
        :key="item.title"
        cols="6"
        offset="3"
      >
        <base-material-card
          :to="item.to"
          :color="item.color"
          :icon="item.icon"
          :title="item.title"
          :text="item.text"
          class="px-5 py-3 mt-10"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: 'Support',
  data: () => ({
    issuesCategories: [
      {
        to: '/support/it',
        color: 'success',
        icon: 'mdi-power',
        title: 'Technical support'
        // text: 'All technical stuff'
      },
      {
        to: '/support/acc',
        color: 'blue',
        icon: 'mdi-file-multiple',
        title: 'Accounting support'
        // text: 'All technical stuff'
      },
      {
        to: '/support/mgnt',
        color: 'purple',
        icon: 'mdi-email-open',
        title: 'Management support'
        // text: 'All technical stuff'
      }
    ]
  })
}
</script>
<style scoped>

</style>
